@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

:root {
  --site-color: #3a7afe;
  --main-color: #ebeef6;
  --active-color: #d4def7;
  --bg-color: #ecedf0;
  --second-color: #f1f1f1;
  --third-color: #9fa7bd;
  --fourth-color: #fff;
  --fifth-color: #000;
  --sixth-color: #222;
  --main-bg-color: #192039;
  --second-bg-color: #1e2746;
  --third-bg-color: #3a7afe;
  --fourth-bg-color: #131b38;
  --primary-color: #0088cc;
  --success-color: #47a447;
  --info-color: #5bc0de;
  --warning-color: #ed9c28;
  --danger-color: #d2322d;

  --primary-color-second: #cfe2ff;
  --success-color-second: #d1e7dd;
  --info-color-second: #cff4fc;
  --warning-color-second: #fff3cd;
  --danger-color-second: #f8d7da;
}

body {
  background-color: var(--main-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container-login {
  width: 400px;
  display: flex;
  flex-direction: row;
  margin: 0 5px;
}

.wrapper-login {
  width: 100%;
  background: var(--second-color);
  border-radius: 5px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
}

.wrapper-login .title {
  height: 90px;
  background: var(--main-bg-color);
  border-radius: 5px 5px 0 0;
  color: var(--main-color);
  font-size: 30px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-login .form-login {
  padding: 25px;
}

.wrapper-login .form-login .row {
  height: 45px;
  margin-bottom: 15px;
  position: relative;
}

.wrapper-login .form-login .row input,
.wrapper-login .form-login .row .button-login {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 60px;
  border-radius: 5px;
  border: 1px solid var(--active-color);
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-login .row input::placeholder {
  color: var(--third-color);
}

.wrapper-login .form-login .row i {
  position: absolute;
  width: 47px;
  height: 100%;
  color: var(--main-color);
  font-size: 18px;
  background: var(--second-bg-color);
  border: 1px solid var(--active-color);
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-login .form-login .button .button-login {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 300;
  padding-left: 0px;
  background: var(--main-bg-color);
  border: 1px solid var(--active-color);
  cursor: pointer;
}

.form-login .button .button-login:hover {
  background: var(--second-bg-color);
}

.wrapper-login .form-login .signup-link {
  text-align: center;
  margin-top: 20px;
  font-size: 17px;
}

.wrapper-login .form-login .signup-link .link-pg-login {
  color: var(--main-bg-color);
  text-decoration: none;
}

.form-login .signup-link .link-pg-login:hover {
  color: var(--site-color);
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

/*Alerta*/

.alert-primary {
  background-color: var(--primary-color-second);
  color: var(--primary-color);
  margin: 10px 0;
  border-radius: 4px;
  padding: 12px 7px;
}

.alert-success {
  background-color: var(--success-color-second);
  color: var(--success-color);
  margin: 10px 0;
  border-radius: 4px;
  padding: 12px 7px;
}

.alert-warning {
  background-color: var(--warning-color-second);
  color: var(--warning-color);
  margin: 10px 0;
  border-radius: 4px;
  padding: 12px 7px;
}

.alert-danger {
  background-color: var(--danger-color-second);
  color: var(--danger-color);
  margin: 10px 0;
  border-radius: 4px;
  padding: 12px 7px;
}

.alert-info {
  background-color: var(--info-color-second);
  color: var(--info-color);
  margin: 10px 0;
  border-radius: 4px;
  padding: 12px 7px;
}

.navbar {
  color: var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--fourth-bg-color);
  position: sticky;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
  padding: 5px 15px;
  font-size: 17px;
  top: 0;
  z-index: 1;
}

.navbar .navbar-content {
  display: flex;
  align-items: center;
}

.navbar .bars,
.notification {
  margin-right: 20px;
  cursor: pointer;
}

.logo {
  width: 130px;
}

.notification,
.avatar {
  cursor: pointer;
  position: relative;
}

.notification .number {
  position: absolute;
  font-size: 13px;
  font-weight: bold;
  background-color: var(--site-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: var(--second-color);
  right: -10px;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}


/*Dropdown navbar*/
.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--main-bg-color);
  width: 280px;
  border-radius: 7px;
  display: none;
}

.dropdown-content {
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-menu li,
.dropdown-menu .item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--second-bg-color);
  cursor: pointer;
  font-size: 15px;
}

.dropdown-menu li img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.dropdown-menu li .text {
  font-style: italic;
  margin-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown-menu li:hover,
.dropdown-menu .item:hover {
  background-color: var(--second-bg-color);
}

/*Apresenta a msg inteira*/
.dropdown-menu li:hover .text {
  white-space: pre-wrap;
}

.dropdown-menu .item span {
  margin-right: 15px;
}

.dropdown-menu .item a {
  text-decoration: none;
  color: var(--bg-color);
}

.dropdown-menu.setting {
  width: 150px;  
}

.dropdown-menu.setting.dropdown-menu-action {
  display: block;
}

/*Início do conteúdo*/

.content {
  display: flex;
}

/*Início da Sidebar*/

.content .sidebar {
  color: var(--main-color);
  height: calc(100vh - 60px);
  width: 60px;
  /*width: 250px;*/
  position: sticky;
  background-color: var(--main-bg-color);
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.12);
  top: 60px;
  overflow: auto;
  overflow-x: hidden;
  transition: 0.3s width ease-in-out;
  flex-shrink: 0;
}

@media(max-width: 768px){
  .content .sidebar {
    width: 60px;
    position: fixed;
    z-index: 1;
    width: 0;
  }
}

.content .sidebar:hover,
.content .sidebar.active {
  width: 250px;
}

.content .sidebar-active {
  width: 250px;
}

.sidebar .sidebar-nav {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px 0;
  position: relative;
  cursor: pointer;
  transition: 0.3s background ease;
  font-size: 16px;
}

.sidebar a {
  color: var(--main-color);
}

.sidebar a:hover {
  background-color: var(--second-bg-color);
  color: var(--active-color);
}

.sidebar .sidebar-nav.active {
  background-color: var(--second-bg-color);
  color: var(--active-color);
}

.sidebar .sidebar-nav.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: var(--site-color);
}

.sidebar-nav .icon {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  flex-shrink: 0;
}

/*Conteúdo*/

.content .wrapper {
  width: 100%;
  padding: 10px;
  background-color: var(--bg-color);
}

.content .row {
  background-color: var(--fourth-color);
  text-align: left;
  border-radius: 6px;
  margin: 10px;
}

.wrapper .row {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  flex-wrap: wrap;
}

.row .box {  
  width: 11%;
  margin: 5px 0.5%;
  height: 230px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fourth-color);
  border-radius: 5px;  
  overflow: hidden;
  flex-direction: column;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.12);

}

.row .box-first {
  background: var(--primary-color);
}

.row .box-second {
  background: var(--success-color);
}

.row .box-third {
  background: var(--warning-color);
}

.row .box-fourth {
  background: var(--danger-color);
}

.box span {
  margin: 5px 0;
  font-size: 12px;  
  position: relative;
}

.div-head {
  width: -webkit-fill-available;
  height: 50px;
  margin: 5px 0;
  font-size: 25px;
  text-align: center;
  color: var(--fourth-color);
  background: var(--site-color);
  border: 1px solid var(--fourth-color);
  position: relative;
}

.div-body {
  width: -webkit-fill-available;
  height: 25px;
  margin: 2px 0;
  font-size: 12px;
  text-align: center;
  color: var(--fourth-color);
  background: var(--third-color);
  border: 1px solid var(--fourth-color);
  position: relative;
}

@media (max-width: 1024px) {
  .row .box {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .row .box {
    width: 98%;
  }
}

@media (max-width: 600px) {
  .wrapper .row {
    padding: 10px 0;
  }
}

/*Listar*/

.row .top-content-adm {  
  width: 100%;
  overflow: hidden;
}

.row .content-adm2 {  
  width: 100%;
  display: flex;
  justify-content: space-between;  
}


.top-content-adm .title-content-veiculo {
  float: left;
  color: var(--danger-color);
  padding: 10px 10px;
  font-size: 17px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;  
}

.top-content-adm .title-content {
  float: left;
  color: var(--main-bg-color);
  padding: 10px 16px;
  font-size: 17px;
}

.top-content-adm .top-content-adm-right {
  padding: 10px 5px 5px 0;
  float: right;
}

.alert-content-adm{
  width: 100%;
  overflow: hidden;
  margin: 0 5px;
}

.table-list {
  margin-top: 5px;
  width: 100%;
}

.table-list .list-head .list-head-content {
  background-color: var(--main-color);
  color: var(--fourth-bg-color);
  padding: 10px;
}

.table-list .list-body .list-body-content {
  background-color: var(--fourth-color);
  color: var(--fourth-bg-color);
  padding: 8px;
}

/*Dashboard table*/

.table-list .list-head-dashboard .list-head-content-dashboard {
  background-color: var(--main-color);
  color: var(--fourth-bg-color);
  padding: 10px;
  text-align: center;
}

.table-list .list-body-dashboard .list-body-content-dashboard {
  background-color: var(--fourth-color);
  color: var(--fourth-bg-color);
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  /*border: 1px solid;*/
}

.table-list .list-body-dashboard .list-body-content-dashboard-alert {
  background-color: var(--fourth-color);
  color: var(--danger-color);
  padding: 8px;
  border-bottom: 1px solid #ddd;  
  /*border: 1px solid;*/
}

/* Fim Dashboard table*/

@media (max-width: 540px) {
  .table-sm-none {
    display: none;
  }
}

@media (max-width: 720px) {
  .table-md-none {
    display: none;
  }
}

@media (max-width: 960px) {
  .table-lg-none {
    display: none;
  }
}

@media (max-width: 1140px) {
  .table-xl-none {
    display: none;
  }
}

/*Paginação*/

.content-pagination { 
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: inline-block;
  font-size: 15px;
}

.pagination a {
  color: var(--fourth-bg-color);
  float: left;
  padding: 8px 10px;
  text-decoration: none;
  transition: background-color 3s;
}

.pagination a.active {
  background-color: var(--second-bg-color);
  color: var(--fourth-color);
}

.pagination a:hover:not(.active) {
  background-color: var(--main-color);
}

/*Personalizar botão*/

.btn-primary {
  background-color: var(--primary-color);
  color: var(--fourth-color);
  padding: 5px 8px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: all .3s ease;
}

.btn-primary:hover {
  background-color: var(--fourth-color);
  color: var(--primary-color);
}

.btn-success {
  background-color: var(--success-color);
  color: var(--fourth-color);
  padding: 5px 8px;
  border: 1px solid var(--success-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: all .3s ease;
}

.btn-success:hover {
  background-color: var(--fourth-color);
  color: var(--success-color);
}

.btn-warning {
  background-color: var(--warning-color);
  color: var(--fourth-color);
  padding: 5px 8px;
  border: 1px solid var(--warning-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: all .3s ease;
}

.btn-warning:hover {
  background-color: var(--fourth-color);
  color: var(--warning-color);
}

.btn-danger {
  background-color: var(--danger-color);
  color: var(--fourth-color);
  padding: 5px 8px;
  border: 1px solid var(--danger-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: all .3s ease;
}

.btn-danger:hover {
  background-color: var(--fourth-color);
  color: var(--danger-color);
}

.btn-info {
  background-color: var(--info-color);
  color: var(--fourth-color);
  padding: 5px 8px;
  border: 1px solid var(--info-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: all .3s ease;
}

.btn-info:hover {
  background-color: var(--fourth-color);
  color: var(--info-color);
}

/* Botão dropdown */

.dropdown-btn-action {
  background-color: var(--primary-color);
  color: var(--fourth-color);
  padding: 5px 8px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: all .3s ease;
}

.dropdown-btn-action:hover,
.dropdown-btn-action:focus {
  background-color: var(--fourth-color);
  color: var(--primary-color);
}

.dropdown-action {
  position: relative;
  display: inline-block;
}

.dropdown-action-item {
  display: none;
  position: absolute;
  background-color: var(--fourth-color);
  min-width: 130px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}

.dropdown-action-item a {
  color: var(--sixth-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-action-item a:hover {
  background-color: var(--main-color);
}

.show-dropdown-action {
  display: block;
}

/*Formulário*/

.wrapper .content-adm {  
  width: 100%;
  padding: 10px;
}

.form-adm {
  width: 100%;
  position: relative;
  margin: 10px auto 0 auto;
}

.row-input {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px 30px;
  margin-bottom: 20px;
}

.title-input {
  color: var(--fourth-bg-color);
  font-size: 14px;
}

input.input-adm,
select.select-adm,
textarea.input-adm {
  width: 100%;
  font-weight: 400;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1.2px solid var(--active-color);
  margin-top: 5px;
}

textarea.input-adm {
  resize: none;
}

input.input-adm:focus,
textarea.input-adm:focus {
  outline: none;
  border-color: var(--site-color);
}



/*Visualizar*/

.content-adm .view-det-adm {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.view-det-adm .view-adm-title {
  width: 80px;
  color: var(--fourth-bg-color);
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}

.view-det-adm .view-adm-info {
  width: 100%;
  color: var(--second-bg-color);
  font-size: 15px;
  padding: 3px 10px;
}

/*Graficos*/

#chart {
  max-width: 650px;
  margin: 35px auto;
}
